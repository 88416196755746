<template>
  <div class="h100 d-flex flex-column mloyalty-panel-burger-body">
    <div class="flex-grow-1">
      <div class="row px-8 pt-5 text-left">
        <div class="col-12 py-0 mb-5">
          <div class="ml-text-20-32-600">Проверка баланса</div>
        </div>
        <div class="col-12 py-0">
          <v-text-field
            color="dark"
            autofocus
            prepend-inner-icon="+7"
            append-icon="mdi-check"
            v-mask="'(###) ###-##-##'"
            required
            height="60"
            label="Ваш телефон*"
            :rules="phoneRules"
            class="ml-input ml-input-prepend-inner ml-hide-details mb-4"
            :class="{ novalidate: validatePhone === false }"
            outlined
            autocomplete="off"
            :disabled="isSentVerificationCode || isLoading"
            v-model="phone"
            @keydown.enter="requestCode"
          ></v-text-field>
          <button
            v-if="!successVerification && !isSentVerificationCode"
            :disabled="!validatePhone"
            href="#"
            class="ml-black-btn w100"
            @click.stop="requestCode"
          >
            Подтвердить телефон
          </button>
          <div class="ml-text-11-14 mt-2 mb-4">
            Нажимая «Подтвердить телефон», Вы соглашаетесь с
            <button @click="docsDialog(1)"><u>Офертой</u></button>
          </div>
        </div>

        <div class="col-12 pt-0 pb-10">
          <verification-code
            @success="successVerificationProcess"
            v-if="isSentVerificationCode && !successVerification"
            :type="channelType"
            :phone="phone"
            :codeType="balanceCodeType"
          />
          <template v-if="successVerification">
            <div class="col-12 px-0 pt-0 mb-2">
              <v-icon class="success-message">mdi-check-circle</v-icon>
              <span class="ml-2">Номер подтвержден!</span>
            </div>
            <div class="py-0">
              <v-text-field
                color="dark"
                append-icon="mdi-check"
                required
                height="60"
                autofocus
                label="Номер сертификата*"
                ref="certificate"
                :rules="numberRules"
                class="ml-input ml-input-prepend-inner ml-hide-details mb-0"
                :class="{
                  novalidate: validateCertificate === false,
                  'error--text v-input--has-state': this.errors
                }"
                outlined
                :disabled="isLoading"
                autocomplete="off"
                @input="errors = null"
                v-model="numberOfCertificate"
              ></v-text-field>
              <v-text-field
                color="dark"
                append-icon="mdi-check"
                required
                height="60"
                label="ПИН-код*"
                :rules="numberRules"
                class="ml-input ml-input-prepend-inner ml-hide-details"
                :class="{
                  novalidate: validatePin === false,
                  'error--text v-input--has-state': this.errors
                }"
                outlined
                autocomplete="off"
                v-model="pincode"
                :disabled="isLoading"
                @input="errors = null"
              ></v-text-field>
            </div>
            <div class="error-message mt-4" v-if="errors">
              {{ errors }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="controlls">
      <button
        :disabled="
          !validatePhone || !validatePin || !validateCertificate || isLoading
        "
        href="#"
        @click.prevent="varifyCardAndPin"
        class="ml-black-btn w100"
      >
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          width="2"
          size="20"
        />
        Узнать баланс
      </button>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import verificationCode from '@/components/Panel/VerificationCode'
import { mapActions } from 'vuex'
import verificationCodeTypes from '@/store/verificationCode/types'
import Constants from '@/helpers/constants'
import ownerTypes from '@/store/owner/types'
import { PHONE_REGEX } from '@/helpers/regex'
import common from '@/helpers/common'
import docDialogMixin from '@/helpers/mixins/docDialog'

export default {
  directives: {
    mask
  },
  components: {
    verificationCode
  },
  mixins: [docDialogMixin],
  data: () => ({
    errors: null,
    isLoading: false,
    numberOfCertificate: null,
    pincode: null,
    phone: null,
    // phone: 9224870500,
    // numberOfCertificate: 55555,
    // pincode: 111,
    successVerification: null,
    isSentVerificationCode: false,
    phoneRules: [
      v => !!v || 'Необходимо заполнить Телефон',
      value =>
        PHONE_REGEX.test(common.getClearPhone(value)) ||
        common.getClearPhone(value) === '6666666666' ||
        'Введите корректный Телефон'
    ],
    numberRules: [v => !!v || 'Необходимо заполнить поле']
  }),
  computed: {
    channelType() {
      return Constants?.SEND_METHOD?.SMS?.COMMUNICATION_TYPE // = 1
    },
    balanceCodeType() {
      return Constants?.SMS_CODE_TYPE?.BALANCE // CodeType = 2
    },
    validatePhone() {
      return (
        (this.phone?.length === 15 && PHONE_REGEX.test(this.clearPhone)) ||
        this.clearPhone === '6666666666'
      )
    },
    validateCertificate() {
      return this.numberOfCertificate?.length > 0
    },
    validatePin() {
      return this.pincode?.length > 0
    },
    clearPhone() {
      return this.phone
        ?.replaceAll(' ', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
    }
  },
  methods: {
    ...mapActions('verificationCode', [verificationCodeTypes.REQUEST_CODE]),
    ...mapActions('owner', [ownerTypes.VERIFY_CERT_CARD_AND_PIN_ACTION]),
    async varifyCardAndPin() {
      this.isLoading = true
      const result = await this[ownerTypes.VERIFY_CERT_CARD_AND_PIN_ACTION]({
        sertCard: this.numberOfCertificate,
        secretCode: this.pincode,
        phone: this.clearPhone
      })
      setTimeout(() => {
        if (result?.success) {
          this.$router.push({
            name: 'Balance',
            params: { sertCard: this.numberOfCertificate }
          })
        } else {
          this.isLoading = false
          this.errors = result?.message
        }
      }, 1000)
    },
    tryAgaint() {
      this.errors = null
      this.pincode = null
      this.numberOfCertificate = null
      this.phone = null
      this.successVerification = null
      this.isSentVerificationCode = false
    },
    requestCode() {
      const phone = this.clearPhone
      const communicationType = this.channelType
      const codeType = this.balanceCodeType
      this[verificationCodeTypes.REQUEST_CODE]({
        phone,
        communicationType,
        codeType
      }).then(() => (this.isSentVerificationCode = true))
    },
    successVerificationProcess() {
      setTimeout(() => {
        this.successVerification = true
      }, 600)
    }
  }
}
</script>
