<template>
  <input
    v-if="any"
    v-bind="$attrs"
    class="form-control ml-input-code"
    :class="{ invalid }"
    :value="value"
    @input="$emit('input', $event.target.value)"
    maxlength="1"
  />
  <input
    v-else
    v-bind="$attrs"
    class="form-control ml-input-code"
    :class="{ invalid }"
    :value="value"
    @input="$emit('input', $event.target.value)"
    maxlength="1"
    v-mask="currentMask"
  />
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'MlInputCode',
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input'
  },
  directives: { mask },
  props: {
    value: {
      type: null,
      required: true
    },
    invalid: {
      type: Boolean
    },
    type: {
      type: String,
      required: false,
      default: 'number'
    },
    any: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    currentMask() {
      if (this.type === 'text') {
        return ''
      }
      return '#'
    }
  }
}
</script>
