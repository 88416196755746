<template>
  <div class="row">
    <div class="col-12 pt-0">
      <p class="text1">Введите код подтверждения</p>
    </div>
    <div class="col-12 d-flex align-center">
      <MlInputCode
        :invalid="invalidCode"
        ref="code1"
        v-model="number1OfCode"
        type="number"
        class="mr-2"
        @input="proccessNumber1"
      />
      <MlInputCode
        ref="code2"
        :invalid="invalidCode"
        v-model="number2OfCode"
        type="number"
        @input="proccessNumber2"
        class="mr-2"
      />
      <MlInputCode
        ref="code3"
        :invalid="invalidCode"
        v-model="number3OfCode"
        type="number"
        @input="proccessNumber3"
        class="mr-2"
      />
      <MlInputCode
        ref="code4"
        :invalid="invalidCode"
        v-model="number4OfCode"
        type="number"
        @input="proccessNumber4"
        class="mr-2"
      />

      <v-progress-circular
        v-if="processVerificationCode"
        indeterminate
        class="ml-2"
        width="2"
        color="#000000"
      ></v-progress-circular>
    </div>
    <div class="col-12 error-message" v-if="invalidCode">
      Введён неверный код подтверждения
    </div>
    <div class="col-12 text2">
      Код подтверждения отправлен Вам в смс
    </div>
    <!-- <div v-if="isMessenger" class="col-12 text2">
      Ссылка для получения кода подтверждения в Telegram, отправлена Вам в смс.
      Перейдите по ссылке и получите код подтверждения.
    </div> -->
    <slot name="text"> </slot>
    <div class="col-12 text-center text2" v-if="!showResendBtn">
      {{ timerForResendText }}
    </div>
    <div class="col-12 text-center text2" v-if="showResendBtn">
      <a href="#" @click.prevent="startTimerForResend(true)">
        Отправить повторно
      </a>
    </div>
  </div>
</template>

<script>
import {
  VERIFICATION_BY_SMS,
  VERIFICATION_BY_MESSENGER
} from '@/helpers/const/sendingMethod'
import MlInputCode from '@/components/UI/MlInputCode'
import { mapActions, mapState } from 'vuex'
import verificationCodeTypes from '@/store/verificationCode/types'
import ownerTypes from '@/store/owner/types'
import { debounce } from 'lodash'

const TIMER_SECONDS = 30
const DEFAULT_COMMUNICATION_TYPE = 1

export default {
  props: {
    type: {
      type: Number,
      required: true
    },
    // 1 - подтверждение телефона при оформлении
    // 2 - код для проверки баланса
    codeType: {
      type: Number,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    email: {
      type: String,
      required: false
    }
  },
  components: {
    MlInputCode
  },
  data: () => ({
    timerForResend: {
      seconds: TIMER_SECONDS,
      id: null
    },
    successVerification: null,
    showResendBtn: false,
    number1OfCode: null,
    number2OfCode: null,
    number3OfCode: null,
    number4OfCode: null
  }),
  computed: {
    ...mapState({
      processVerificationCode: state =>
        state.verificationCode.isInitCodeInProccess
    }),
    timerForResendText() {
      return `Отправить повторно можно через ${this.timerForResend.seconds}с`
    },
    invalidCode() {
      return this.successVerification === false
    },
    // isSms() {
    //   return this.type === VERIFICATION_BY_SMS
    // },
    // isMessenger() {
    //   return this.type === VERIFICATION_BY_MESSENGER
    // },
    clearPhone() {
      return this.phone
        ?.replaceAll(' ', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
    }
  },
  created() {
    const debounceTimeout = process.env.VUE_APP_DEBOUNCE_TIMEOUT ?? 1000
    this.debounced = {
      sendVerificationCodeViaSms: debounce(
        this.sendVerificationCodeViaSms,
        debounceTimeout
      )
      // sendVerificationCodeViaMessanger: debounce(
      //   this.sendVerificationCodeViaMessanger,
      //   debounceTimeout
      // )
    }
    this.startTimerForResend(false)
  },
  mounted() {
    this.$refs?.code1?.$el?.focus()
    setTimeout(() => {
      window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })
    }, 150)
  },
  methods: {
    ...mapActions('verificationCode', [
      verificationCodeTypes.SEND_VERIFICATIONCODE_VIA_SMS,
      verificationCodeTypes.SEND_VERIFICATIONCODE_VIA_MESSANGER,
      verificationCodeTypes.REQUEST_CODE
    ]),
    ...mapActions('owner', [ownerTypes.CREATE_OR_UPDATE_OWNER_ACTION]),
    resetCode() {
      this.number1OfCode = null
      this.number2OfCode = null
      this.number3OfCode = null
      this.number4OfCode = null
      this.successVerification = null
    },
    startTimerForResend(isResend = true) {
      if (isResend === true) {
        this.showResendBtn = false
        this.resetCode()
        const phone = this.clearPhone
        const communicationType = DEFAULT_COMMUNICATION_TYPE
        const codeType = this.codeType
        this[verificationCodeTypes.REQUEST_CODE]({
          phone,
          communicationType,
          codeType
        }).then(() => {
          this.timerForResend.seconds = TIMER_SECONDS
          this.timerForResend.id = setInterval(this.timerForResending, 1000)
          this.$refs?.code1?.$el?.focus()
        })
      } else {
        this.showResendBtn = false
        this.timerForResend.seconds = TIMER_SECONDS
        this.timerForResend.id = setInterval(this.timerForResending, 1000)
      }
    },
    timerForResending() {
      if (this.timerForResend.seconds !== 1) {
        this.timerForResend.seconds -= 1
      } else {
        clearInterval(this.timerForResend.id)
        this.showResendBtn = true
      }
    },
    sendVerificationCodeViaSms(code) {
      const data = {
        code: code,
        phone: this.clearPhone,
        email: this.email,
        name: this.name
      }
      this[verificationCodeTypes.SEND_VERIFICATIONCODE_VIA_SMS](data).then(
        result => {
          this.successVerification = result
          if (result === true) {
            // TODO разкоментить когда вернут метод в АПИ
            // this[ownerTypes.CREATE_OR_UPDATE_OWNER_ACTION](data)
            this.$emit('success')
          }
        }
      )
    },
    // sendVerificationCodeViaMessanger(code) {
    //   const data = {
    //     code: code,
    //     phone: this.clearPhone,
    //     email: this.email,
    //     name: this.name
    //   }
    //   this[verificationCodeTypes.SEND_VERIFICATIONCODE_VIA_MESSANGER](
    //     data
    //   ).then(result => {
    //     this.successVerification = result
    //     if (result === true) {
    //       this[ownerTypes.CREATE_CERT_OWNER_ACTION](data)
    //       this.$emit('success')
    //     }
    //   })
    // },
    proccessNumber1() {
      this.successVerification = null
      this.number2OfCode = null
      this.number3OfCode = null
      this.number4OfCode = null
      if (
        this.number1OfCode &&
        Number.isInteger(parseInt(this.number1OfCode))
      ) {
        this.$refs.code2.$el.focus()
      }
    },
    proccessNumber2() {
      this.successVerification = null
      this.number3OfCode = null
      this.number4OfCode = null
      if (
        this.number2OfCode &&
        Number.isInteger(parseInt(this.number2OfCode))
      ) {
        this.$refs.code3.$el.focus()
      }
    },
    proccessNumber3() {
      this.successVerification = null
      this.number4OfCode = null
      if (
        this.number3OfCode &&
        Number.isInteger(parseInt(this.number3OfCode))
      ) {
        this.$refs.code4.$el.focus()
      }
    },
    proccessNumber4() {
      this.successVerification = null
      this.$refs.code4.$el.blur()
      if (
        this.number4OfCode &&
        Number.isInteger(parseInt(this.number4OfCode))
      ) {
        const code1 = this.number1OfCode
        const code2 = this.number2OfCode
        const code3 = this.number3OfCode
        const code4 = this.number4OfCode
        if (code1 && code2 && code3 && code4) {
          const code = `${code1}${code2}${code3}${code4}`
          // if (this.type === VERIFICATION_BY_SMS) {
          this.debounced.sendVerificationCodeViaSms(code)
          // } else {
          // this.debounced.sendVerificationCodeViaMessanger(code)
          // }
        }
      }
    }
  }
}
</script>
